// DMGridContainer.jsx
import React, { useEffect, useState, Component } from "react";
import { cloneDeep } from "lodash";
import { DotLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select, { components } from "react-select";
import { useMdmDataChunks } from "./useMdmDataChunks"; // adjust the path as needed
import DMGrid from "./dmGrid";
import { LoadingIndicator } from "../../../../lib/loadingIndicator";
import { setNextRowToLoad } from "../../reducers/mdmReducer"; // <— We’ll create this action

class Option extends Component {
  render() {
    return (
      <div>
        <components.Option {...this.props}>
          <input type="checkbox" checked={this.props.isSelected} onChange={() => null} /> <label>{this.props.value} </label>
        </components.Option>
      </div>
    );
  }
}
const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.selectProps.value.length > 1) {
    labelToBeDisplayed = "multiple selections";
    return (
      <components.MultiValue {...props}>
        <span style={{ fontStyle: "italic" }}>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  if (props.selectProps.value !== null && props.selectProps.value.length > 1) {
    // Only show "multiple selections" text, not each selection
    return <components.ValueContainer {...props}>{[children[0][0], children[1]]}</components.ValueContainer>;
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const DMGridContainer = props => {
  const { currentProject, mdmConfig, activeIdx, currentAppIdx, lastId, setDirty } = props;
  const dispatch = useDispatch();
  const [loadedRows, setLoadedRows] = useState(0);
  const [totalRows, setTotalRows] = useState(mdmConfig[activeIdx].rowCount || 0);
  const [selectedQuickFilters, setSelectedQuickFilters] = useState(null);

  const [placeholderRows, setPlaceholderRows] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  // console.log("TOTAL ROWS " + totalRows);
  // console.log(mdmConfig);
  const queries = useMdmDataChunks(currentProject, mdmConfig[activeIdx].id, mdmConfig[activeIdx].rowCount, 50000);

  // Check overall loading/error state
  // const isLoading = queries.some(q => q.isLoading);
  const isError = queries.some(q => q.isError);
  const error = queries.find(q => q.error)?.error;
  function addPlaceholderRows() {
    // Suppose you want 1000 placeholders
    const newRows = [];
    let lastId = 0;
    if (rowData.length > 0) {
      // If the chunk data has ID columns, find the largest ID
      lastId = rowData[rowData.length - 1].ID;
    }
    for (let i = 1; i <= 1000; i++) {
      newRows.push({
        ID: lastId + i,
        crudType: "empty"
        // any other columns
      });
    }
    // console.log("NEW ROWS");
    // console.log(newRows);
    setPlaceholderRows(newRows);
  }

  if (isError) return <div>Error: {error.message}</div>;

  // Combine only the loaded chunks:
  const rowData = queries.filter(q => q.data && q.data.data).flatMap(q => q.data.data);
  useEffect(() => {
    // rowData.length is how many rows have been loaded
    dispatch(setNextRowToLoad(rowData.length));
  }, [rowData.length, dispatch]);
  useEffect(() => {
    setLoadedRows(rowData.length);
  }, [rowData.length]);
  useEffect(() => {
    setTotalRows(mdmConfig[activeIdx].rowCount || 0);
  }, [mdmConfig, activeIdx]);
  const loadingMore = queries.some(q => q.isFetching);

  // const isLoading = queries.some(q => q.isLoading || q.isFetching);
  // If all data is loaded, add placeholders once
  useEffect(() => {
    // A basic condition to detect "all loaded"
    if (
      rowData.length === totalRows &&
      !loadingMore &&
      placeholderRows.length === 0 // only add them once
    ) {
      addPlaceholderRows();
    }
  }, [rowData.length, totalRows, loadingMore]);
  // useEffect(() => {
  //   if (onProgressUpdate) {
  //     onProgressUpdate(loadedRows, totalRows, isLoading);
  //   }
  // }, [loadedRows, totalRows, isLoading, onProgressUpdate]);

  const finalData = [...rowData, ...placeholderRows];
  function addRow() {
    let newData = null;
    if (finalData.length > 0) {
      newData = cloneDeep(finalData[0]);
      newData = Object.entries(newData).reduce((obj, [k, v]) => {
        if (k === "ID") {
          obj[k] = lastId + 1;
        } else {
          obj[k] = null;
        }
        return obj;
      }, {});
    } else {
      newData = this.state.currentTable.reduce((obj, item) => {
        if (item.headerName === "ID") {
          obj[item.headerName] = 1;
        } else {
          obj[item.headerName] = null;
        }
        return obj;
      }, {});
    }

    newData.crudType = "insert";
    this.props.addNewRow(newData);
    setDirty(true);
  }
  function quickFilterClick(item) {
    const { externalFilter } = this.props;
    let newExternalFilter = [];

    if (item.length === 0) {
      // If user cleared the multi-select
      // ...
    } else {
      newExternalFilter = cloneDeep(externalFilter);
      if (newExternalFilter.length === 0) {
        newExternalFilter = item;
      } else {
        // Remove or add
        newExternalFilter = newExternalFilter.filter(filter => filter.field !== item.field);
      }
    }
    this.props.setExternalFilter(newExternalFilter);
    setSelectedQuickFilters(item);

    this.props.setRefresh(true);
  }
  function deleteModal() {
    setShowDeleteConfirmModal(true);
  }
  // A small local progress display
  function renderProgress() {
    if (loadedRows >= totalRows && totalRows > 0) {
      return <div style={{ marginRight: "30px" }}>{`Completed ${totalRows} Rows`}</div>;
    } else if (loadedRows > 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            marginRight: "30px"
          }}>
          <DotLoader size={15} color="rgba(0, 126, 167, 1)" loading />
          <div
            style={{
              color: "rgba(0, 126, 167, 1)",
              fontStyle: "italic",
              marginLeft: "5px"
            }}>
            {`Loading ${loadedRows}/${totalRows} Rows`}
          </div>
        </div>
      );
    }
    return null;
  }
  // console.log("FINAL DATA");
  // console.log(finalData);
  if (finalData.length === 0) {
    return <LoadingIndicator />;
  }
  let quickFilters = [];
  if (mdmConfig[activeIdx]?.data) {
    quickFilters = mdmConfig[activeIdx].data
      .filter(col => col.cellRenderer === "checkboxCellRenderer")
      .map(f => ({ ...f, value: f.headerName, label: f.headerName }));
  }
  // const loadedRowCount = rowData.length;
  // const progress = totalRows > 0 ? (loadedRowCount / totalRows) * 100 : 0;
  function handleClose() {
    let idx = parseInt(activeIdx, 10);
    this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${idx}`);
    setShowDeleteConfirmModal(false);
  }
  // Pass rowData into DMGrid as a prop (you can also pass a callback if needed)
  return (
    <>
      <Modal isOpen={showDeleteConfirmModal}>
        <ModalHeader>Delete Row</ModalHeader>
        <ModalBody>
          <div>Are you sure you want to delete the selected row? This cannot be undone.</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.agGrid.current.checkRemove();
            }}>
            Delete
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* Quick Filters */}
        <div>
          {quickFilters.length > 0 && (
            <div style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}>
              <div>Filter:</div>
              <div>
                <Select
                  className="quick-filter-dropdown"
                  classNamePrefix="react-select-qf"
                  placeholder="none"
                  value={selectedQuickFilters}
                  onChange={e => quickFilterClick(e)}
                  options={quickFilters}
                  closeMenuOnSelect={false}
                  isMulti
                  components={{ Option, MultiValue, ValueContainer }}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  isClearable={false}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="pull-right action-buttons"
          style={{ display: "inline-flex", marginLeft: "auto", marginBottom: "10px", marginTop: "10px" }}>
          {renderProgress()}
          <div
            onClick={() => {
              if (mdmConfig[activeIdx].allowNewRows) addRow();
            }}
            className={"mdm-action-button " + (mdmConfig[activeIdx].allowNewRows ? "" : "disabled")}
            style={{ marginLeft: "auto", marginRight: "20px", width: "92px" }}>
            +Add Row
          </div>
          <div
            style={{ width: "100px" }}
            onClick={() => {
              if (mdmConfig[activeIdx].allowNewRows && this.agGrid.current.checkRemoveStatus()) {
                deleteModal();
              }
            }}
            className={"mdm-action-button " + (mdmConfig[activeIdx].allowNewRows ? "" : "disabled")}>
            Delete Row
          </div>
        </div>
      </div>
      <DMGrid {...props} loadingMore={loadingMore} rowData={finalData} />
    </>
  );
};

export default DMGridContainer;
