// useMdmDataChunks.js
import { useQueries } from "@tanstack/react-query";
import axios from "axios";

const fetchMdmDataChunk = async ({ queryKey, signal }) => {
  const [, table, start, limit] = queryKey;
  console.log("fetchMdmDataChunk re-fetching:", { table, start, limit });
  const response = await axios.post(
    `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getMdmData`,
    { table, start, limit },
    { headers: { ClientToken: localStorage.getItem("clientToken") }, signal }
  );
  return response.data;
};

export function useMdmDataChunks(project, table, totalRows, chunkSize = 5000) {
  // Build an array of query configs, one per chunk.
  const pages = [];
  if (totalRows > 0) {
    // First chunk: limit 1000
    pages.push({ table, start: 0, limit: 1000 });
  }
  // console.log("THE CHUNG SIZE " + chunkSize);
  for (let start = 1000; start < totalRows; start += chunkSize) {
    pages.push({ table, start, limit: chunkSize });
  }

  const queries = useQueries({
    queries: pages.map(page => ({
      queryKey: ["mdmDataChunk", table, page.start, page.limit],
      queryFn: fetchMdmDataChunk,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000
    }))
  });
  return queries;
}
